
/* Board Style - Gallery */
.board-list-gallery {
    width: 100%;
    border-top:1px solid var(--v-primary-base);
}
.board-list-gallery .row{ margin: 0; }
.board-list-gallery .row [class*="col"]{
    padding:12px 4px;
    border-bottom:1px solid var(--v-primary-base);
}
.board-list-gallery__inner, .board-list-gallery__link{
    position: relative;
}
.board-list-gallery__link{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
}
.board-list-gallery__left{
    position: relative;
    width: 100%;
}
.board-list-gallery__img-wrap{
    position: relative;
    width: 100%;
    padding-top: calc(240/360 * 100%);
    margin: auto;
    border:1px solid #efefef;
    overflow: hidden;
    background-color: #efefef;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    background-image:url(/images/common/noimg.png);
}
.board-list-gallery__img{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    -webkit-transition: transform ease-out .5s; -ms-transition: transform ease-out .5s; transition: transform ease-out .5s;
    animation: FadeIn .4s ease-in-out .5s forwards;
    opacity: 0;
}
.board-list-gallery__img::before{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: center;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease-out .5s; -ms-transition: all ease-out .5s; transition: all ease-out .5s;
}
.board-list-gallery__right{
    width: 100%;
    padding:10px 0 0;
}
.board-list-gallery__con{
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
}
.board-list-gallery__date{
    font-size: 1.4rem;
    color: var(--v-primary-base);
    margin-top: 10px;
}
.board-list-gallery__tit-wrap{
    position: relative;
}
.board-list-gallery__tit{
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
    font-family: var(--font-notoserif);
}
.board-list-gallery__more{
    display:none;
    flex-wrap:wrap;
    align-items: center;
    font-size: 1.4rem;
    color: var(--v-primary-base);
    font-family: var(--font-notosans);
    margin:12px 0 0;
}
.board-list-gallery__txt{
    font-size: 1.4rem;
    color: #686868;
    margin-top: 4px;
    overflow: hidden; text-align: left; text-overflow: ellipsis; white-space: normal; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
}
.board-list-gallery__html{
    display: block;
    height: 3.2em;
    line-height: 1.6;
}
.board-list-gallery__html >*{
    max-width: 100%;
}
.board-list-gallery .board-empty-message{
    padding: 60px 20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .board-list-gallery__left{
        width: 200px;
    }
    .board-list-gallery__right{
        width: calc(100% - 200px);
        padding: 0 0 0 30px;
    }
    .board-list-gallery__date{
        font-size: 1.6rem;
        margin-top: 16px;
    }
    .board-list-gallery__tit{
        font-size: 1.8rem;
    }
    .board-list-gallery__txt{
        margin-top: 10px;
    }
    .board-list-gallery__more{
        display:inline-block;
        margin: 0;
    }
    .board-list-gallery .board-empty-message{
        padding: 20px 10px;
    }
}
@media (min-width:1024px){
    .board-list-gallery .row{ margin:0; }
    .board-list-gallery .row [class*="col"]{
        padding:26px 0;
    }
    .board-list-gallery__left {
        width: 360px;
    }
    .board-list-gallery__inner >a:hover .board-list-gallery__img::before{
        opacity: 1;
        visibility: visible;
    }
    .board-list-gallery__inner >a:hover .board-list-gallery__img{
        opacity: 1;
        visibility: visible;
        transform: translate(-50%,-50%) scale(1.2);
    }
    .board-list-gallery__right{
        width: calc(100% - 360px);
        padding: 20px 0 20px 50px;
    }
    .board-list-gallery__con{
        padding-bottom: 10px;
    }
    .board-list-gallery__tit{
        font-size: 2.4rem;
    }
    .board-list-gallery__txt{
        font-size: 1.6rem;
    }
    .board-list-gallery__more{
        font-size: 1.6rem;
    }
    .board-list-gallery .board-empty-message{
        padding: 40px 20px;
    }
}
